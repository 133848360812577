import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./Home.module.css";
import SearchBar from "../../../components/filters/SearchBar";
import { Link } from "react-router-dom";
import Faq from "./Faq";
import HighlightVideo from "../../../img/MuscoVision_highlight_video.mp4";
import MagnifyingGlass from "../../../img/magnifying-glass-icon.svg";
import NearbyIcon from "../../../img/nearby-link-icon.svg";
import SubscriptionIcon from "../../../img/subscription-icon.svg";
import PlayBtnIcon from "../../../img/play-button-icon.svg";
import TvIcon from "../../../img/TV-icon.svg";
import PhoneIcon from "../../../img/mobile-phone-icon.svg";

import LaptopIcon from "../../../img/laptop-icon.svg";
import TabletIcon from "../../../img/tablet-icon.svg";
import AppStoreIcon from "../../../img/appstore-download.png";
import PlayStoreIcon from "../../../img/googleplay-download.png";
import { useDispatch, useSelector } from "react-redux";
// import { setCurrentModal } from "../../../redux/actions/auth";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { useSelector, useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { setCurrentModal } from "../../../redux/actions/modal";
const Home = (props) => {
	const [pricingIsOpen, setPricingIsOpen] = useState(false);
	const screenWidth = useSelector((state) => state.general.screenWidth);
	const dispatch = useDispatch();
	//GA page view
	useEffect(() => {
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	useEffect(() => {
		if (props.location.hash === "#pricing") {
			setPricingIsOpen(true);
		}
	}, [props.location.hash]);

	return (
		<div className={styles.home}>
			<Helmet>
				<title>MuscoVision</title>
				<meta name="description" content="Musco Vision" />
			</Helmet>
			<section className={styles.featuredVideo}>
				<div className={styles.videoWrapper}>
					<video preload="none" autoPlay="autoplay" loop controls muted src={HighlightVideo} width="500"></video>
				</div>
			</section>
			<section className={styles.find}>
				<h1 className={styles.findTitle}>FIND A GAME</h1>
				<div className={styles.blueHorizontalRule}></div>
				<p className={styles.findDesc}>Search for a game or facility.</p>
				<div className={styles.searchBar}>
					<SearchBar placeholder="SEARCH" />
				</div>

				<Link to="/nearby" className={styles.gamesNearMeLink}>
					<img style={{ marginRight: ".5rem" }} src={NearbyIcon} alt="Games Near Me Icon" />
					FIND GAMES NEAR ME
				</Link>
			</section>
			<section id="pricing" className={styles.started}>
				<h1 className={styles.startedTitle}>How to get started</h1>

				<div className={styles.startedBlockContainer}>
					<div className={styles.startedBlock}>
						<img className={styles.startedBlockIcon} src={MagnifyingGlass} alt="Temp" />
						<h3 className={styles.startedBlockTitle}>1. FIND A GAME</h3>
						<p className={styles.startedBlockDesc}>
							Choose from live and pre-recorded games that can be watched on demand.
						</p>
					</div>
					<div className={styles.startedBlock}>
						<img className={styles.startedBlockIcon} src={SubscriptionIcon} alt="Temp" />
						<h3 className={styles.startedBlockTitle}>2. PICK A PLAN</h3>
						<p className={styles.startedBlockDesc}>
							Choose from one-, three-, seven-, or 30-day subscriptions to best meet your needs.
						</p>
						<button className={styles.pricingToggle} onClick={() => setPricingIsOpen(!pricingIsOpen)}>
							Review Pricing
						</button>
					</div>
					<div className={styles.startedBlock}>
						<img className={styles.startedBlockIcon} src={PlayBtnIcon} alt="Temp" />
						<h3 className={styles.startedBlockTitle}>3. START WATCHING</h3>
						<p className={styles.startedBlockDesc}>
							Access and view games via your phone, tablet, computer, or Smart TV.
						</p>
					</div>
				</div>
				<div style={pricingIsOpen ? { maxHeight: "200px" } : { maxHeight: 0 }} className={styles.pricingContainer}>
					<h3>ACCESS PASS PRICING</h3>
					<div className={styles.pricingRow}>
						<span>1-DAY</span>
						<span>$6.99</span>
					</div>
					<div className={styles.pricingRow}>
						<span>3-DAY</span>
						<span>$14.99</span>
					</div>
					<div className={styles.pricingRow}>
						<span>7-DAY</span>
						<span>$19.99</span>
					</div>
					<div className={styles.pricingRow}>
						<span>30-DAY</span>
						<span>$24.99</span>
					</div>
				</div>

				<button type="button" className={styles.startedSignUp} onClick={() => dispatch(setCurrentModal("register"))}>
					SIGN UP NOW
				</button>
			</section>
			<section className={styles.about}>
				<div className={styles.aboutContainer}>
					<h1 className={styles.aboutTitle}>
						<span>WHAT IS </span>MuscoVision?
					</h1>
					<p className={styles.aboutDesc}>
						MuscoVision™ automated sports broadcasting makes it possible for fans, friends, and family who can’t attend
						a game in person to never again have to miss out on the action. The system delivers a fluid,
						professional-level video production, with games that are easy to find and fun to watch. Viewers choose from
						a variety of subscription options ranging from one-day to 30-day passes, and can watch live and pre-recorded
						games on demand. MuscoVision provides 24/7 technical support.
					</p>
				</div>
			</section>
			<div className={styles.gradientBackground}>
				<section className={styles.ways}>
					<h1 className={styles.waysTitle}>Ways to watch</h1>
					<div className={styles.waysBlockContainer}>
						<div className={styles.waysBlock}>
							<img className={styles.waysBlockImg} src={TvIcon} alt="Temp" />
							<h3 className={styles.waysBlockTitle}>
								TV <p className={styles.waysBlockDesc}>(Coming soon)</p>
							</h3>

							<p className={styles.waysBlockDesc}>
								Watch on your smart TVs and connected devices including: Amazon Fire TV/Stick, Android TV, Apple TV,
								Chromecast, ROKU, &amp; more.
							</p>
						</div>
						<div className={styles.waysBlock}>
							<img className={styles.waysBlockImg} src={PhoneIcon} alt="Temp" />
							<h3 className={styles.waysBlockTitle}>MOBILE</h3>
							<p className={styles.waysBlockDesc}>Download our app for Android and iOS</p>
						</div>
						<div className={styles.waysBlock}>
							<img className={styles.waysBlockImg} src={LaptopIcon} alt="Temp" />
							<h3 className={styles.waysBlockTitle}>COMPUTER</h3>
							<p className={styles.waysBlockDesc}>Chrome, Firefox, Safari, Edge, Internet Explorer and Opera</p>
						</div>
						<div className={styles.waysBlock}>
							<img className={styles.waysBlockImg} src={TabletIcon} alt="Temp" />
							<h3 className={styles.waysBlockTitle}>TABLET</h3>
							<p className={styles.waysBlockDesc}>Download our app for Android and iOS</p>
						</div>
					</div>
					<div className={styles.getTheApp}>
						<h4>GET THE APP</h4>
						<div className={styles.storeDownloads}>
							<a href="https://apps.apple.com/us/app/muscovision/id1463098619">
								<img src={AppStoreIcon} alt="App Store logo" />
							</a>
							<a href="https://play.google.com/store/apps/details?id=com.musco.muscovision&hl=en_US">
								<img src={PlayStoreIcon} alt="Google Play Store logo" />
							</a>
						</div>
					</div>
				</section>
				<section className={styles.faq}>
					<h1 className={styles.faqTitle}>
						Frequently asked
						<br />
						questions
					</h1>

					<Faq
						question="How does it work?"
						answer="MuscoVision™ automated broadcasting allows viewers who can’t attend games to watch live and pre-recorded streamed broadcasts."
					/>
					<Faq
						question="How long do I have access to watch games?"
						answer={[
							"You can choose from one-, three-, seven-, and 30-day Access Passes for purchase at ",
							<a href="https://www.muscovision.com">www.muscovision.com</a>,
							".",
						]}
					/>
					<Faq
						question="How much do the different Access Passes cost?"
						answer={[
							"a. One-day $6.99",
							<br />,
							"b. Three-day $14.99",
							<br />,
							"c. Seven-day $19.99",
							<br />,
							"d. 30-day $24.99",
						]}
					/>
					<Faq
						question="What methods of payment are accepted?"
						answer="You can purchase your Access Pass using Visa or Mastercard."
					/>
					<Faq
						question="Can I watch any games I want?"
						answer="Yes, with an active day pass you can watch any live or on-demand broadcast at any facility carried by MuscoVision."
					/>
					<Faq
						question="What if I can’t watch a game live?"
						answer="All games are recorded and stored for three months, so if a live broadcast is missed you can still watch it on demand with your Access Pass. Downloads may be available depending on the facility."
					/>
					<Faq
						question="How do I get help with a payment or technical issue?"
						answer={["Please contact at ", <a href="tel:8444340587">(844) 434-0587</a>, "."]}
					/>
					<Faq
						question="How do I get MuscoVision at my facility or for my team?"
						answer={[
							"You can find out more at ",
							<a target="_blank" href="https://www.musco.com/muscovision/">
								www.musco.com/muscovision/
							</a>,
							" or send a direct request from ",
							<a target="_blank" href="https://www.musco.com/info-muscovision/">
								www.musco.com/info-muscovision/
							</a>,
							".",
						]}
					/>
				</section>
			</div>
		</div>
	);
};

export default Home;
